<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-white" v-slot="{ open }">
    <div class="px-2 sm:px-6">
      <div class="relative flex items-center justify-between h-12">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="
              inline-flex
              items-center
              justify-center
              p-2
              rounded-md
              text-gray-400
              hover:text-white
              hover:bg-gray-700
              focus:outline-none
              focus:ring-2 focus:ring-inset focus:ring-white
            "
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div
          class="
            flex-1 flex
            items-center
            justify-center
            sm:items-stretch
            sm:justify-start
          "
        >
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/">
              <div class="flex flex-row xl:hidden items-center gap-2">
                <i class="fas fa-crown"></i>
                <p
                  class="
                    block
                    xl:hidden
                    w-auto
                    font-thin font-inter
                    text-xl
                    select-none
                    text-gray-900
                  "
                >
                  Admin
                </p>
              </div>
            </router-link>
            <router-link to="/">
              <div class="hidden xl:flex flex-row items-center gap-2">
                <i class="fas fa-crown"></i>
                <p class="w-auto font-inter font-thin text-xl text-gray-900">
                  Admin
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <!-- user acc -->
        <div>
          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton
                class="
                  justify-center
                  w-full
                  px-4
                  py-2
                  text-sm
                  font-medium
                  text-white
                  bg-gray-900
                  rounded-md
                  hover:bg-gray-700
                  focus:outline-none
                  focus-visible:ring-2
                  focus-visible:ring-white
                  focus-visible:ring-opacity-75
                "
              >
                Bok, {{ name }}
                <i
                  class="
                    fas
                    fa-chevron-down
                    w-5
                    h-5
                    ml-1
                    -mr-1
                    text-violet-200
                    hover:text-violet-100
                  "
                ></i>
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="
                  absolute
                  right-0
                  w-56
                  mt-2
                  origin-top-right
                  bg-white
                  divide-y divide-gray-100
                  rounded-md
                  shadow-lg
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                "
              >
                <MenuItem>
                  <button class="w-full hover:bg-gray-200" @click="lout">
                    <div
                      class="
                        self-start
                        flex flex-row
                        text-center
                        py-2
                        px-5
                        gap-2
                      "
                    >
                      <i
                        class="fas fa-sign-out-alt text-red-700 self-center"
                      ></i>
                      <p>Odjavi se</p>
                    </div>
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <div v-for="section in navigation" class="flex flex-col pb-2">
          <p class="text-gray-600 text-xs font-medium uppercase pl-4 py-1">
            {{ section.section }}
          </p>

          <router-link
            v-for="child in section.children"
            :to="child.href"
            class="
              flex flex-row
              px-1
              py-2
              pl-4
              items-center
              gap-2
              hover:bg-gray-100
              active:bg-blue-100
            "
          >
            <i :class="[child.icon]" class="text-sm text-gray-600 w-5"></i>
            <p class="text-gray-800 text-sm">{{ child.name }}</p>
          </router-link>
          <hr />
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import store from "@/store";
import router from "@/router";

import { logout } from "@/helpers/api.js";

const navigation = [
  {
    section: "webshop",
    children: [
      {
        name: "Statistika",
        href: "/test",
        icon: "fas fa-chart-line",
      },
      {
        name: "Proizvodi",
        href: "/",
        icon: "fas fa-box",
      },
      {
        name: "Narudžbe",
        href: "/",
        icon: "fas fa-clipboard-list",
      },
      {
        name: "Prihodi",
        href: "/",
        icon: "far fa-money-bill-alt",
      },
    ],
  },
  {
    section: "korisnici",
    children: [
      {
        name: "Statistika",
        href: "/",
        icon: "fas fa-chart-line",
      },
      {
        name: "Admini",
        href: "/",
        icon: "fas fa-user-shield",
      },
      {
        name: "Ostali",
        href: "/	",
        icon: "fas fa-users",
      },
      {
        name: "Popis dozvola",
        href: "/dozvole",
        icon: "fas fa-users",
      },
    ],
  },
];

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
  },
  setup() {
    const open = ref(false);
    const name = store.state.user.username;
    const lout = async () => {
      try {
        await logout();
        router.push({
          name: "Login",
        });
      } catch (error) {
        console.log(error);
      }
    };
    return {
      navigation,
      open,
      name,
      lout,
    };
  },
};
</script>