import axios from 'axios';
import router from '../router';
import store from '../store';

const HTTP = axios.create({
	baseURL: `${process.env.VUE_APP_BASE_URL_METHOD}://${process.env.VUE_APP_BASE_URL}/api/v1`,
});

HTTP.defaults.withCredentials = true;

HTTP.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response.status == 401) {
		store.commit('logout');
		router.push({ name: "Login" });
	}
	return Promise.reject(error);

});

export { HTTP };