import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index.js';
import Login from '@/views/Login.vue';
import e404 from '@/views/errors/e404.vue';
import { HTTP } from '@/helpers/http-common.js';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/Home.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		props: true
	},
	{
		path: '/dozvole',
		name: 'Perms',
		component: () => import('@/views/users/Perms.vue'),
		props: true
	},
	{
		path: '/kategorije',
		name: 'Categories',
		component: () => import('@/views/shop/categories/Categories.vue'),
		props: true
	},
	{
		path: '/podkategorije',
		name: 'Subcategories',
		component: () => import('@/views/shop/subcategories/Subcategories.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)',
		name: '404',
		component: e404
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	const check = ((!store.state.user.logged_in) || (store.state.user.session_expires_at - Date.now() <= 0));
	if (check && to.name != "Login") {

		store.commit("logout");
		next({
			name: "Login",
			params: {
				to: to.path
			}
		});
	}
	// if (!check && to.name == "Login") {
	// 	next({ path: from.path || "/" });
	// }
	next();
});

const DEFAULT_TITLE = 'Administrator | Elektroservis Panjkrc';

router.afterEach((to, from) => {
	document.title = to.meta.title ? `${to.meta.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;

});


export default router;
