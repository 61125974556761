import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

export default createStore({
	state: {
		user: {
			logged_in: false,
			session_expires_at: 0,
			username: ""
		}

	},
	mutations: {
		login: (state, user_data) => {
			state.user.logged_in = true;
			state.user.session_expires_at = user_data.session_expires_at;
			state.user.username = user_data.name;
		},
		logout: state => {
			state.user.logged_in = false;
			state.user.session_expires_at = 0;
		}
	},
	actions: {
	},
	modules: {
	},
	plugins: [createPersistedState()],
});
