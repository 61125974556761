<template>
  <div
    class="
      py-2
      hidden
      sm:flex
      flex-col
      justify-start
      w-72
      border-r border-gray-200
      overflow-y-auto
      font-inter
    "
  >
    <div v-for="section in navigation" class="flex flex-col pb-2">
      <p class="text-gray-600 text-xs font-medium uppercase pl-4 py-1">
        {{ section.section }}
      </p>

      <router-link
        v-for="child in section.children"
        :to="child.href"
        class="
          flex flex-row
          px-1
          py-2
          pl-4
          items-center
          gap-2
          hover:bg-gray-100
          active:bg-blue-100
        "
      >
        <i :class="[child.icon]" class="text-sm text-gray-600 w-5"></i>
        <p class="text-gray-800 text-sm">{{ child.name }}</p>
      </router-link>
      <hr />
    </div>
  </div>
</template>

<script>
const navigation = [
  {
    section: "webshop",
    children: [
      {
        name: "Statistika",
        href: "/test",
        icon: "fas fa-chart-line",
      },
      {
        name: "Proizvodi",
        href: "/",
        icon: "fas fa-box",
      },
	  {
        name: "Menu",
        href: "/menu",
        icon: "fas fa-bars",
      },
	  {
		  name: "Kategorije",
		  href: "/kategorije",
		  icon: "fas fa-folder-open"
	  },
	   {
		  name: "Podkategorije",
		  href: "/podkategorije",
		  icon: "fas fa-folder-open"
	  },
      {
        name: "Narudžbe",
        href: "/",
        icon: "fas fa-clipboard-list",
      },
      {
        name: "Prihodi",
        href: "/",
        icon: "far fa-money-bill-alt",
      },
    ],
  },
  {
    section: "korisnici",
    children: [
      {
        name: "Statistika",
        href: "/",
        icon: "fas fa-chart-line",
      },
      {
        name: "Admini",
        href: "/",
        icon: "fas fa-user-shield",
      },
      {
        name: "Ostali",
        href: "/",
        icon: "fas fa-users",
      },
	//   {
    //     name: "Popis dozvola",
    //     href: "/dozvole",
    //     icon: "fas fa-user-lock",
    //   },
    ],
  },
];

export default {
  setup() {
    return {
      navigation,
    };
  },
};
</script>

<style>
</style>