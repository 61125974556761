import { HTTP } from './http-common.js';
import store from '../store';


export const login = (username, password) => {
	return new Promise(async (resolve, reject) => {
		try {
			let params = new URLSearchParams();
			params.append("username", username);
			params.append("password", password);
			const response = await HTTP.post('/admin/login', params);
			store.commit("login", response.data.user);
			return resolve();
		} catch (error) {
			reject(error.response.data.message);
		}
	});
};

export const logout = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.post('/admin/logout');
			store.commit("logout");
			return resolve();
		} catch (error) {
			reject(error);
		}
	});
};

export const permissions = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.get('/admin/permissions');
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const addCategory = (data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.post('/admin/category', data);
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const getCategories = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.get('/admin/category');
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const editCategory = (data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.patch('/admin/category', data);
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteCategory = (data) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log(data);
			const response = await HTTP.delete('/admin/category', {
				data: {
					_id: data._id,
					name: data.name
				}
			});
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};


export const addSubcategory = (data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.post('/admin/subcategory', data);
			return resolve(response);
		} catch (error) {
			console.log(error)
			reject(error);
		}
	});
};

export const getSubcategories = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.get('/admin/subcategory');
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const editSubcategory = (data) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await HTTP.patch('/admin/subcategory', data);
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteSubcategory = (data) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log(data);
			const response = await HTTP.delete('/admin/subcategory', {
				data: {
					_id: data._id,
					name: data.name
				}
			});
			return resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};