<template>
  <div v-if="!isLogin" class="flex flex-col h-screen">
    <div class="w-screen sticky top-0 z-50">
      <Nav />
      <hr />
    </div>
    <div class="flex flex-row overflow-y-auto h-full">
      <Sidebar />
      <div class="w-full bg-gray-100 overflow-y-auto h-full" id="content">
        <router-view />
      </div>
    </div>
  </div>
  <div v-else class="h-screen">
    <router-view />
  </div>
</template>


<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
import Sidebar from "./components/Sidebar.vue";

export default {
  setup() {},
  components: {
    Nav,
    Footer,
    Sidebar,
  },
  computed: {
    isLogin() {
      return this.$route.name === "Login";
    },
  },
};
</script>


