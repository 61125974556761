<template>
  <div class="flex flex-col justify-center content-center h-full font-inter">
    <div
      class="
        self-center
        md:w-80
        w-3/4
        h-auto
        m-5
        border-2 border-gray-200
        rounded-xl
        bg-gray-50
        p-5
      "
    >
      <div class="flex flex-row justify-center items-center w-full gap-2 pb-4">
        <p class="text-center font-bold italic text-3xl">ESP</p>
      </div>
      <hr />
      <form
        class="flex flex-col py-5 gap-4"
        id="kvar-form"
        @submit.prevent="onSubmit"
      >
        <div class="col-span-2 w-full">
          <p class="font-thin text-gray-900 text-base">Korisničko ime</p>
          <input
            v-model="username"
            type="text"
            name="username"
            id="username"
            class="
              bg-gray-200
              focus:ring-gray-900
              focus:bg-white
              focus:shadow-md
              border-none
              rounded-lg
              w-full
            "
            required
          />
        </div>

        <div class="col-span-2 w-full">
          <p class="font-thin text-gray-900 text-base">Lozinka</p>
          <input
            v-model="password"
            type="password"
            name="password"
            id="password"
            class="
              bg-gray-200
              focus:ring-gray-900
              focus:bg-white
              focus:shadow-md
              border-none
              rounded-lg
              w-full
            "
            required
          />
        </div>
        <hr />
        <button
          class="
            w-full
            text-white
            bg-gray-900
            p-2
            rounded-lg
            font-bold
            hover:bg-gray-700
            active:bg-gray-300
            active:text-gray-900
          "
        >
          Prijavi se
        </button>
      </form>
    </div>
    <div
      v-if="error"
      class="
        self-center
        md:w-80
        w-3/4
        bg-red-300
        border-2 border-red-500
        text-white
        p-2
        h-auto
        rounded-lg
		text-center
      "
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import { HTTP } from "../helpers/http-common.js";
import { login } from "../helpers/api.js";
export default {
  data() {
    return {
      username: "",
      password: "",
      error: "",
    };
  },
  props: ["to"],

  methods: {
    async onSubmit() {
      try {
        await login(this.username, this.password);
        this.$router.push(this.to || "/");
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

<style>
</style>